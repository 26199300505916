import { render, h } from "preact";
import { useState } from "preact/hooks";
import { MessageCirclePlus, ChevronDown } from "lucide-preact";

function AmenitiWidget() {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div>
      <div>
        <div
          id="ameniti-widget-button"
          onClick={() => {
            setIsVisible(!isVisible);
          }}
          role="button"
          tabIndex={0}
          aria-label="Open AmenitiGPT Chat"
          aria-live="polite"
          style={styles.button}
        >
          <div style={styles.buttonContent}>
            {isVisible ? (
              <ChevronDown size={35} stroke={"#fff"} />
            ) : (
              <MessageCirclePlus size={35} stroke={"#fff"} />
            )}
          </div>
        </div>
        <div
          id="ameniti-widget-root"
          style={{
            ...styles.widgetRoot,
            transform: isVisible ? "scale(1)" : "scale(0)",
            opacity: isVisible ? 1 : 0,
          }}
        >
          <div style={styles.widgetContent}>
            <iframe
              allowFullScreen
              name="amenitigpt-frame"
              title="AmenitiGPT"
              role="dialog"
              src="https://amenitigpt.com/chat/amenitigpt"
              style={styles.iframe}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  button: {
    position: "fixed",
    zIndex: 2147483003,
    padding: "0 !important",
    margin: "0 !important",
    border: "none",
    bottom: "20px",
    right: "20px",
    maxWidth: "70px",
    width: "70px",
    maxHeight: "70px",
    height: "70px",
    borderRadius: "50%",
    background: "#007aff",
    cursor: "pointer",
    boxShadow:
      "0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16)",
    transition:
      "transform 167ms cubic-bezier(0.33, 0, 0, 1), background-color 167ms cubic-bezier(0.33, 0, 0, 1)",
    boxSizing: "content-box",
  },
  buttonContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    left: 0,
    width: "70px",
    height: "70px",
    transition: "transform 100ms linear, opacity 80ms linear",
  },
  chevronIcon: {
    display: "none",
  },
  chatIcon: {
    marginTop: "-4px",
  },
  widgetRoot: {
    zIndex: 2147483000,
    position: "fixed",
    bottom: "104px",
    right: "20px",
    transformOrigin: "right bottom",
    height: "min(704px, 100% - 104px)",
    minHeight: "80px",
    width: "700px",
    maxWidth: "90%",
    maxHeight: "704px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 5px 40px",
    borderRadius: "16px",
    overflow: "hidden",
    opacity: 1,
    transition:
      "width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s",
    pointerEvents: "all",
    transform: "scale(0)",
  },
  widgetContent: {
    fontFamily:
      "system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    fontSize: "100%",
    fontStyle: "normal",
    letterSpacing: "normal",
    fontStretch: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    textAlignLast: "initial",
    textDecoration: "none",
    textEmphasis: "none",
    textIndent: "0px",
    textShadow: "none",
    textTransform: "none",
    alignmentBaseline: "baseline",
    animationPlayState: "running",
    backfaceVisibility: "visible",
    backgroundColor: "transparent",
    backgroundImage: "none",
    baselineShift: "baseline",
    border: "0px none transparent",
    borderRadius: "0px",
    inset: "auto",
    WebkitBoxDecorationBreak: "slice",
    boxShadow: "none",
    boxSizing: "content-box",
    captionSide: "top",
    clear: "none",
    clip: "auto",
    color: "inherit",
    columns: "auto",
    columnFill: "balance",
    columnGap: "normal",
    content: "normal",
    counterIncrement: "none",
    counterReset: "none",
    cursor: "auto",
    direction: "ltr",
    display: "inline",
    dominantBaseline: "auto",
    emptyCells: "show",
    float: "none",
    height: "auto",
    hyphenateCharacter: "auto",
    hyphens: "manual",
    imageRendering: "auto",
    lineHeight: "inherit",
    listStyle: "outside none disc",
    margin: "0px",
    maxHeight: "none",
    maxWidth: "none",
    minHeight: "0px",
    minWidth: "0px",
    opacity: 1,
    orphans: 2,
    outlineOffset: "0px",
    overflow: "visible",
    padding: "0px",
    page: "auto",
    breakAfter: "auto",
    breakBefore: "auto",
    breakInside: "auto",
    perspective: "none",
    perspectiveOrigin: "50% 50%",
    pointerEvents: "auto",
    position: "static",
    quotes: "none",
    resize: "none",
    size: "auto",
    tableLayout: "auto",
    transform: "none",
    transformOrigin: "50% 50% 0px",
    transformStyle: "flat",
    unicodeBidi: "normal",
    verticalAlign: "baseline",
    whiteSpace: "normal",
    widows: 2,
    width: "auto",
    wordBreak: "normal",
    wordSpacing: "normal",
    overflowWrap: "normal",
    zIndex: "auto",
    textAlign: "start",
    WebkitFontSmoothing: "antialiased",
  },
  iframe: {
    width: "100%",
    height: "100%",
    position: "absolute",
    border: "none",
  },
};

const App = <AmenitiWidget />;

// Inject our app into the DOM

// Renders: <div>My name is John Doe.</div>
const el = document.createElement("div");
el.id = "yeet";
el.style.position = "absolute";
render(App, el);
document.body.appendChild(el);
